import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import EditIcon from '@material-ui/icons/Edit';
import localeEmoji from 'locale-emoji';
import {setLocale} from '../appSlice';

BtnEditWithLocales.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function BtnEditWithLocales(props) {
  const {onClick} = props;
  const locales = useSelector(state => state.app.locales);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleBtnClick = event => {
    event.stopPropagation();
    dispatch(setLocale(locales[selectedIndex]));
    onClick(event, locales[selectedIndex]);
  };

  const handleMenuItemClick = (event, index) => {
    event.stopPropagation();
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = event => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const menuItems = locales.map((locale, index) => (
    <MenuItem
      key={locale}
      disabled={index === 2}
      selected={index === selectedIndex}
      onClick={(event) => handleMenuItemClick(event, index)}
    >
      {localeEmoji(locale)}
    </MenuItem>
  ));

  return (
    <>
      <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
        <Button onClick={event => handleBtnClick(event)} startIcon={<EditIcon/>}>Modifier</Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {localeEmoji(locales[selectedIndex])}
        </Button>
      </ButtonGroup>
      <Popper style={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement='bottom-end'>
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {menuItems}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default BtnEditWithLocales;
