import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { removeUser, openDialog, fetchUser} from '../userSlice';
import ConfirmDialog from '../../../components/ConfirmDialog';

export default function UserRow(props) {
  const {user} = props;
  const loading = useSelector(state => state.users.loading);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const dispatch = useDispatch();

  const handleEditModal = e => {
    e.preventDefault();
    dispatch(fetchUser(user.id));
    dispatch(openDialog('edit'));
  }

  return (
    <TableRow key={user.id}>
      <TableCell scope="row">{user.email}</TableCell>
      <TableCell>
        <Button onClick={event => handleEditModal(event)} startIcon={<EditIcon/>} variant="contained" color="primary">Modifier</Button>
        <IconButton onClick={() => setConfirmOpen(true)} disabled={loading.user === user.id} color="secondary" aria-label="Supprimer">
          <DeleteIcon/>
        </IconButton>
        <ConfirmDialog
          title={'Supprimer ' + user.email + ' ?'}
          confirmLabel='Supprimer'
          confirmColor='secondary'
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          onConfirm={() => dispatch(removeUser(user.id))}
        >
          <p>Êtes-vous sur de vouloir supprimer {user.email} ?</p>
        </ConfirmDialog>
      </TableCell>
    </TableRow>
  );
};
