import React from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import {updateForm, dismissError} from "./userSlice";
import {useDispatch, useSelector} from "react-redux";

export default function UserForm() {
  const form = useSelector(state => state.users.form);
  const errors = useSelector(state => state.users.errors);
  const dispatch = useDispatch();

  const listErrors = errors.map((error, index) => (
    <Alert key={index} severity={error.severity} onClose={() => dispatch(dismissError(index))}>
      {error.text}
    </Alert>
  ));

  return (
    <Grid container direction="column">
      <Grid item>
        <TextField
          type="email"
          value={form.email}
          onChange={e => dispatch(updateForm({ prop: 'email', value: e.target.value }))}
          label="Email"
          margin="normal"
          fullWidth
          autoComplete="new-password"
          required
        />
      </Grid>
      <Grid item>
        <TextField
          type="password"
          value={form.password}
          onChange={e => dispatch(updateForm({ prop: 'password', value: e.target.value }))}
          label="Mot de passe"
          margin="normal"
          fullWidth
          autoComplete="new-password"
          required
        />
      </Grid>
      {listErrors}
    </Grid>
  );
}
