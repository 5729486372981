import React, {useEffect, useState} from 'react';
import icons from '../data/fontawesome-icons.json';
import './FontAwesomeSelector.scss';

export const CLASS_TYPE = 'class';

const getIconClassNames = (icon, style, key) => {
  let classes = [];
  classes.push('fa' + style.charAt(0));
  classes.push('fa-' + key);
  return classes.join(' ');
};

const getIconFromClassName = className => {
  const iconId = className.split(' ')[1].substring(3);
  const [key, icon] = Object.entries(icons).find(([k, i]) => k === iconId);
  return icon;
};

const getStyleFromClassName = className => {
  const iconStyle = className.split(' ')[0];
  switch (iconStyle) {
    case 'fas':
      return 'solid';
    case 'fab':
      return 'brands';
    default:
      return 'regular';
  }
};

export default function FontAwesomeSelector(props) {
  const { value, onChange, valueType } = props;
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [selectedIconStyle, setSelectedIconStyle] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (valueType === CLASS_TYPE && value) {
      setSelectedIcon(getIconFromClassName(value));
      setSelectedIconStyle(getStyleFromClassName(value));
    }
  }, [value, valueType]);

  const openModal = e => {
    e.preventDefault();
    setModalOpened(!modalOpened);
  };

  const selectIcon = (e, icon, style, key) => {
    e.preventDefault();
    setSelectedIcon(icon);
    setSelectedIconStyle(style);
    setModalOpened(false);
    switch (valueType) {
      case CLASS_TYPE:
        onChange(getIconClassNames(icon, style, key));
        break;
      default:
        onChange(icon, style, key);
    }
  };

  let iconBtns = [];

  for (const [key, icon] of Object.entries(icons).filter(([k, i]) => i.label.includes(searchText) || i.search.terms.join(',').includes(searchText))) {
    for (const style of icon.styles) {
      iconBtns.push((
        <button
          key={icon.unicode + style}
          dangerouslySetInnerHTML={{ __html: icon.svg[style].raw }}
          onClick={e => selectIcon(e, icon, style, key)}
        ></button>
      ));
    }
  }

  return (
    <div className='font-awesome-selector'>
      <button
        className='btn-selector'
        onClick={e => openModal(e)}
        dangerouslySetInnerHTML={{ __html: selectedIcon ? selectedIcon.svg[selectedIconStyle].raw : null }}
      ></button>
      <div className={'modal ' + (modalOpened ? 'opened' : '')}>
        <input type="text" value={searchText} onChange={e => setSearchText(e.target.value)} placeholder="Search"/>
        <div className='icons'>
          {iconBtns}
        </div>
      </div>
    </div>
  );
}
