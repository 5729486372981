import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../../../appSlice';
import { closeModal } from '../../alert/alertDialogSlice';
import ApiService from '../../../app/services/api.service';

export const fetchProducts = createAsyncThunk(
  'products/fetch',
  async () => await ApiService.get('products')
);

export const addProduct = createAsyncThunk(
  'products/add',
  async (arg, { getState, dispatch }) => {
    return await ApiService.post('products', getState().products.newProduct, {}, {
      201: () => dispatch(enqueueSnackbar({ message: 'Produit ajouté avec succès !', options: {variant: 'success'} }))
    });
  }
);

export const removeProduct = createAsyncThunk(
  'products/remove',
  async (productSlug, { getState, dispatch }) => {
    await ApiService.delete('products', productSlug, {}, {
      204: () => {
        dispatch(enqueueSnackbar({ message: 'Produit supprimé avec succès !', options: {variant: 'success'} }));
        dispatch(closeModal());
      }
    });
    return productSlug;
  }
);

const initialState = {
  loading: {
    list: false,
    form: false,
    product: null
  },
  newProduct: {
    name: '',
    price: ''
  },
  items: []
};

export const productListSlice = createSlice({
  name: 'productList',
  initialState: initialState,
  reducers: {
    updateNewProduct: (state, action) => {
      state.newProduct[action.payload.prop] = action.payload.value;
    }
  },
  extraReducers: {
    [fetchProducts.pending]: state => {
      state.loading.list = true;
    },
    [fetchProducts.fulfilled]: (state, action) => {
      state.items = action.payload;
      state.loading.list = false;
    },
    [fetchProducts.rejected]: (state, error) => {
      state.loading.list = false;
    },
    [addProduct.pending]: state => {
      state.loading.form = true;
    },
    [addProduct.fulfilled]: (state, action) => {
      state.items.unshift(action.payload);
      state.newProduct = initialState.newProduct;
      state.loading.form = false;
    },
    [addProduct.rejected]: (state, error) => {
      state.loading.form = false;
    },
    [removeProduct.pending]: (state, action) => {
      state.loading.product = action.meta.arg;
    },
    [removeProduct.fulfilled]: (state, action) => {
      state.items = state.items.filter(i => i.slug !== action.payload);
      state.loading.product = null;
    },
    [removeProduct.rejected]: (state, error) => {
      state.loading.product = null;
    },
  },
});

export const { updateNewProduct } = productListSlice.actions;

export default productListSlice.reducer;
