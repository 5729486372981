import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {setSelectedCategory} from '../productItemSlice';
import CategoryTreeItem from '../../../category/list/CategoryTreeItem';
import AddIcon from '@material-ui/icons/Add';
import FeatureDialog from './FeatureDialog';
import {openDialog} from './featureSlice';

export default function ProductFeatures() {
  const categories = useSelector(state => state.categories.items);
  const selectedCategory = useSelector(state => state.product.selectedCategory);
  const dispatch = useDispatch();

  const treeItems = categories.map(c => <CategoryTreeItem key={c['@id']} category={c} showFeatures/>);

  return (
    <>
      <Button onClick={() => dispatch(openDialog('add'))} startIcon={<AddIcon/>} disabled={selectedCategory === null} variant="contained" color="primary">
        Ajouter une option
      </Button>
      <TreeView
        selected={selectedCategory}
        onNodeSelect={(event, value) => dispatch(setSelectedCategory(value.startsWith('/categories') ? value : null))}
        defaultCollapseIcon={<ArrowDropDownIcon/>}
        defaultExpandIcon={<ArrowRightIcon/>}
      >
        {treeItems}
      </TreeView>
      <FeatureDialog/>
    </>
  );
}
