import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../../../../appSlice';
import ApiService from '../../../../app/services/api.service';
import {addVersion, editVersion, moveVersion as productMoveVersion, removeVersion} from '../productItemSlice';

export const fetchVersion = createAsyncThunk(
  'versions/fetchOne',
  async id => await ApiService.get('versions', id)
);

export const saveVersion = createAsyncThunk(
  'versions/save',
  async (arg, { getState, dispatch }) => {
    const product = getState().product.item;
    const form = getState().versions.form;
    const data = {
      ...form,
      image: form.image['@id'],
      product: product['@id'],
      features: form.features.map(f => ({ ...f, feature: f.feature['@id'], images: f.images.map(i => i['@id']) }))
    };
    let version;
    if (getState().versions.dialog.mode === 'add') {
      version = await ApiService.post('versions', data, {}, {
        201: () => {
          dispatch(enqueueSnackbar({ message: 'Version ajoutée avec succès !', options: {variant: 'success'} }))
        }
      });
      dispatch(addVersion(version));
    } else {
      version =  await ApiService.put('versions', getState().versions.form.id, data, {
        200: () => dispatch(enqueueSnackbar({ message: 'Version modifiée avec succès !', options: {variant: 'success'} }))
      });
      dispatch(editVersion(version));
    }
    return version;
  }
);

export const moveVersion = createAsyncThunk(
  'versions/move',
  async ({version, position}, { getState, dispatch }) => {
    const previousPosition = version.position;
    const newVersion = await ApiService.patch('versions', version.id, { position: position });
    dispatch(productMoveVersion({ version: newVersion, previousPosition: previousPosition }));
    return { version: newVersion, previousPosition: previousPosition };
  }
);

export const deleteVersion = createAsyncThunk(
  'versions/remove',
  async (version, { getState, dispatch }) => {
    await ApiService.delete('versions', version.id, {}, {
      204: () => {
        dispatch(enqueueSnackbar({ message: 'Point fort supprimé avec succès !', options: {variant: 'success'} }));
        dispatch(closeDialog());
      }
    });
    dispatch(removeVersion(version));
    return version;
  }
);

const initialState = {
  loading: {
    list: false,
    form: false
  },
  dialog: {
    mode: 'add',
    open: false
  },
  form: {
    name: '',
    price: '0',
    image: null,
    features: []
  },
  filters: {
    features: {
      name: ''
    }
  },
  selectedFeature: null
};

export const versionSlice = createSlice({
  name: 'versions',
  initialState: initialState,
  reducers: {
    setForm: (state, action) => {
      state.form = action.payload;
    },
    setSelectedFeature: (state, action) => {
      state.selectedFeature = action.payload;
    },
    updateForm: (state, action) => {
      state.form[action.payload.prop] = action.payload.value;
    },
    addFeature: (state, action) => {
      state.form.features.push({ feature: state.selectedFeature, images: [], preSelected: false, standard: false, unavailable: false });
    },
    updateFeature: (state, action) => {
      state.form.features = state.form.features.map(f => {
         if (f.id === action.payload.id) {
           f[action.payload.prop] = action.payload.value;
         }
         return f;
      });
    },
    removeFeature: (state, action) => {
      state.form.features = state.form.features.filter(f => f.id !== action.payload.id)
    },
    addFeatureImage: (state, action) => {
      state.form.features = state.form.features.map(f => {
        if (f.id === action.payload.feature.id) {
          f.images.push(action.payload.image);
        }
        return f;
      });
    },
    removeFeatureImage: (state, action) => {
      state.form.features = state.form.features.map(f => {
        if (f.id === action.payload.feature.id) {
          f.images = f.images.filter(i => i['@id'] !== action.payload.image['@id']);
        }
        return f;
      });
    },
    openDialog: (state, action) => {
      state.form = initialState.form;
      state.dialog.open = true;
      state.dialog.mode = action.payload ?? 'add';
    },
    closeDialog: (state, action) => {
      state.dialog.open = false;
    },
    updateFilters: (state, action) => {
      state.filters[action.payload.entity][action.payload.name] = action.payload.value;
    }
  },
  extraReducers: {
    [fetchVersion.pending]: state => {
      state.loading.form = true;
    },
    [fetchVersion.fulfilled]: (state, action) => {
      state.form = action.payload;
      state.loading.form = false;
    },
    [fetchVersion.rejected]: (state, error) => {
      state.loading.form = false;
    },
    [saveVersion.pending]: state => {
      state.loading.form = true;
    },
    [saveVersion.fulfilled]: (state, action) => {
      state.form = initialState.form;
      state.loading.form = false;
      state.dialog.open = false;
    },
    [saveVersion.rejected]: (state, error) => {
      state.loading.form = false;
    },
    [moveVersion.rejected]: (state, error) => {
      console.log(error)
    },
    [deleteVersion.pending]: (state, action) => {
      state.loading.version = action.meta.arg;
    },
    [deleteVersion.fulfilled]: (state, action) => {
      state.dialog.open = false;
    },
    [deleteVersion.rejected]: (state, error) => {
      state.loading.version = null;
    },
  },
});

export const {
  setForm,
  setSelectedFeature,
  updateForm,
  addFeature,
  updateFeature,
  removeFeature,
  addFeatureImage,
  removeFeatureImage,
  openDialog,
  closeDialog,
  updateFilters
} = versionSlice.actions;

export default versionSlice.reducer;
