import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import {openDialog} from '../versionSlice';
import VersionDialog from '../VersionDialog';
import VersionRow from './VersionRow';
import AddIcon from "@material-ui/icons/Add";

export default function VersionList(props) {
  const {versions} = props;
  const loading = useSelector(state => state.versions.loading.list);
  const dispatch = useDispatch();

  if (loading || !versions) {
    return <CircularProgress/>;
  }

  const rows = versions.slice().sort((v1, v2) => v1.position > v2.position).map(v => <VersionRow key={v.id} version={v}/>);

  return (
    <>
      <Button onClick={() => dispatch(openDialog())} startIcon={<AddIcon/>} variant="contained" color="primary">
        Ajouter une version
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Prix</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
      <VersionDialog/>
    </>
  );
}
