import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch } from 'react-redux';
import HighlightRow from './HighlightRow';
import HighlightDialog from '../HighlightDialog';
import {openDialog} from "../highlightSlice";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function HighlightList() {
  const classes = useStyles();
  const highlights = useSelector(state => state.highlights.items);
  const loading = useSelector(state => state.highlights.loading);
  const dispatch = useDispatch();

  if (loading.list) {
    return <CircularProgress/>;
  }

  const rows = highlights.map(h => <HighlightRow key={h.id} highlight={h}/>);

  return (
    <>
      <Button onClick={() => dispatch(openDialog('add'))} startIcon={<AddIcon/>} variant="contained" color="primary">Ajouter un point fort</Button>
      <hr/>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Icone</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
      <HighlightDialog/>
    </>
  );
}
