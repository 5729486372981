import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import CategoryIcon from '@material-ui/icons/Category';
import StarsIcon from '@material-ui/icons/Stars';
import GroupIcon from '@material-ui/icons/Group';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ListItemNavLink from './components/ListItemNavLink';
import SignIn from './SignIn';
import ProductList from './features/product/list/ProductList';
import ProductItem from './features/product/item/ProductItem';
import CategoryTreeView from './features/category/list/CategoryTreeView';
import HighlightList from './features/highlight/list/HighlightList';
import UserList from './features/user/list/UserList';
import Notifier from './components/Notifier';
import AlertDialog from './features/alert/AlertDialog';
import {currentUser, fetchLocales, fetchDefaultLocale} from './appSlice';
import {fetchProducts} from './features/product/list/productListSlice';
import {fetchCategories} from './features/category/categorySlice';
import {fetchHighlights} from './features/highlight/highlightSlice';
import './App.scss';

library.add(fas, fab, far);
dom.watch()

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function App() {
  const user = useSelector(currentUser);
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  useEffect(() => {
    dispatch(fetchLocales());
    dispatch(fetchDefaultLocale());
    dispatch(fetchProducts());
    dispatch(fetchCategories());
    dispatch(fetchHighlights());
  }, [dispatch]);

  // Check if user is connected
  if (user === null) {
    return <SignIn/>;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItemNavLink icon={<DashboardIcon/>} primary='Dashboard' to='/' exact/>
        <ListItemNavLink icon={<DirectionsBoatIcon/>} primary='Bateaux' to='/products'/>
        <ListItemNavLink icon={<CategoryIcon/>} primary='Catégories' to='/categories'/>
        <ListItemNavLink icon={<StarsIcon/>} primary='Points forts' to='/highlights'/>
        <ListItemNavLink icon={<GroupIcon/>} primary='Utilisateurs' to='/users'/>
      </List>
    </div>
  );

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Administration
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{paper: classes.drawerPaper}}
              ModalProps={{keepMounted: true}}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{paper: classes.drawerPaper}}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Switch>
            <Route exact path="/">

            </Route>
            <Route exact path="/products">
              <ProductList/>
            </Route>
            <Route path="/products/:slug">
              <ProductItem/>
            </Route>
            <Route exact path="/categories">
              <CategoryTreeView/>
            </Route>
            <Route exact path="/highlights">
              <HighlightList/>
            </Route>
            <Route exact path="/users">
              <UserList/>
            </Route>
          </Switch>

        </main>
      </div>
      <Notifier/>
      <AlertDialog/>
    </>
  );
}

export default App;
