import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from '@material-ui/core/Collapse';
import VersionFeatureForm from './VersionFeatureForm';
import {removeFeature} from '../versionSlice';
import './VersionFeatureCard.scss';

VersionFeatureCard.propTypes = {
  versionFeature: PropTypes.object
};

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    transition: '500ms'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function VersionFeatureCard(props) {
  const {versionFeature} = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  const iconExpand = (
    <IconButton color="primary" className={classes.expand + (expanded ? ' ' + classes.expandOpen : '')} onClick={() => setExpanded(!expanded)} aria-label="Ouvrir" aria-expanded={expanded}>
      <ExpandMoreIcon/>
    </IconButton>
  );

  return (
    <Card className="version-feature-card">
      <CardHeader title={versionFeature.feature.name} action={iconExpand}/>
      <Collapse in={expanded}>
        <CardContent>
          <VersionFeatureForm versionFeature={versionFeature}/>
        </CardContent>
        <CardActions>
          <Button onClick={() => dispatch(removeFeature(versionFeature))} variant="contained" color="secondary" startIcon={<DeleteIcon/>}>
            Supprimer
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
}
