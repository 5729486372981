import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../../appSlice';
import { closeModal } from '../alert/alertDialogSlice';
import ApiService from '../../app/services/api.service';

function moveCategoryRecursively(categories, category, previousPosition) {
  let newCategories = categories.map(c => {
    if (c.id === category.id) {
      c = category;
    } else if (
      (
        (typeof c.parent === 'undefined' && typeof category.parent === 'undefined')
        || (typeof c.parent !== 'undefined' && typeof category.parent !== 'undefined' && c.parent === category.parent['@id'])
      )
      && c.position === category.position) {
      c.position = previousPosition;
    }
    return c;
  });

  for (let i = 0; i < newCategories.length; i++) {
    newCategories[i].children = moveCategoryRecursively(newCategories[i].children, category, previousPosition);
  }

  return newCategories;
}

export const fetchCategory = createAsyncThunk(
  'categories/fetchOne',
  async (id, { getState }) => await ApiService.get('categories', id, {
    headers: {
      'X-LOCALE': getState().app.locale
    }
  })
);

export const fetchCategories = createAsyncThunk(
  'categories/fetch',
  async () => await ApiService.query('categories', '', { params: { 'exists[parent]': false }})
);

export const saveCategory = createAsyncThunk(
  'categories/save',
  async (arg, { getState, dispatch }) => {
    if (getState().categories.dialog.mode === 'add') {
      return await ApiService.post('categories', { ...getState().categories.form, parent: getState().categories.selectedCategory }, {}, {
        201: () => dispatch(enqueueSnackbar({ message: 'Catégorie ajoutée avec succès !', options: {variant: 'success'} }))
      });
    } else {
      const category = await ApiService.patch('categories', getState().categories.form.id, {
        ...getState().categories.form,
        parent: getState().categories.form.parent['@id'],
        children: getState().categories.form.children.map(c => c['@id'])
      }, {
        headers: {
          'X-LOCALE': getState().app.locale
        }
      }, {
        200: () => dispatch(enqueueSnackbar({ message: 'Catégorie modifiée avec succès !', options: {variant: 'success'} }))
      });
      return getState().app.locale === getState().app.defaultLocale ? category : null;
    }
  }
);

export const moveCategory = createAsyncThunk(
  'categories/move',
  async ({category, position}, { getState, dispatch }) => {
    const previousPosition = category.position;
    const newCategory = await ApiService.patch('categories', category.id, {
      position: position
    });
    return { category: newCategory, previousPosition: previousPosition };
  }
);

export const removeCategory = createAsyncThunk(
  'categories/remove',
  async (categoryId, { getState, dispatch }) => {
    await ApiService.delete('categories', categoryId, {}, {
      204: () => {
        dispatch(enqueueSnackbar({ message: 'Catégorie supprimée avec succès !', options: {variant: 'success'} }));
        dispatch(closeModal());
      }
    });
    return categoryId;
  }
);

const initialState = {
  loading: {
    list: false,
    form: false
  },
  dialog: {
    mode: 'add',
    open: false
  },
  form: {
    name: ''
  },
  items: [],
  selectedCategory: null
};

export const categorySlice = createSlice({
  name: 'categoryList',
  initialState: initialState,
  reducers: {
    updateForm: (state, action) => {
      state.form[action.payload.prop] = action.payload.value;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    openDialog: (state, action) => {
      state.form = initialState.form;
      state.dialog.open = true;
      state.dialog.mode = action.payload ?? 'add';
    },
    closeDialog: (state, action) => {
      state.dialog.open = false;
    }
  },
  extraReducers: {
    [fetchCategories.pending]: state => {
      state.loading.list = true;
    },
    [fetchCategories.fulfilled]: (state, action) => {
      state.items = action.payload;
      state.loading.list = false;
    },
    [fetchCategories.rejected]: (state, error) => {
      state.loading.list = false;
    },
    [fetchCategory.pending]: state => {
      state.loading.form = true;
    },
    [fetchCategory.fulfilled]: (state, action) => {
      state.form = action.payload;
      state.loading.form = false;
    },
    [fetchCategory.rejected]: (state, error) => {
      state.loading.form = false;
    },
    [saveCategory.pending]: state => {
      state.loading.form = true;
    },
    [saveCategory.fulfilled]: (state, action) => {
      if (state.dialog.mode === 'add') {
        if (action.payload.parent) {
          // TODO: search also in sub-categories
          state.items.find(c => c['@id'] === state.selectedCategory).children.push(action.payload);
        } else {
          state.items.push(action.payload);
        }
      } else if (action.payload !== null) {
        state.items = state.items.map(c => c['@id'] === state.form['@id'] ? action.payload : c);
      }
      state.form = initialState.form;
      state.loading.form = false;
      state.dialog.open = false;
    },
    [saveCategory.rejected]: (state, error) => {
      state.loading.form = false;
    },
    [moveCategory.fulfilled]: (state, action) => {
      state.items = moveCategoryRecursively(state.items, action.payload.category, action.payload.previousPosition);
    },
    [moveCategory.rejected]: (state, error) => {
      console.log(error)
    },
    [removeCategory.pending]: (state, action) => {
      state.loading.category = action.meta.arg;
    },
    [removeCategory.fulfilled]: (state, action) => {
      // TODO: filter also in sub-categories
      state.items = state.items.filter(i => i.id !== action.payload);
      state.selectedCategory = null;
      state.loading.category = null;
    },
    [removeCategory.rejected]: (state, error) => {
      state.loading.category = null;
    },
  },
});

export const { updateForm, setSelectedCategory, openDialog, closeDialog } = categorySlice.actions;

export default categorySlice.reducer;
