import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useDispatch, useSelector} from 'react-redux';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import AddIcon from "@material-ui/icons/Add";
import TreeView from "@material-ui/lab/TreeView";
import {fetchCategory, moveCategory, removeCategory, setSelectedCategory, openDialog} from '../categorySlice';
import {closeModal, openModal} from '../../alert/alertDialogSlice';
import CategoryTreeItem from "./CategoryTreeItem";
import CategoryDialog from "../CategoryDialog";

export default function CategoryTreeView() {
  const categories = useSelector(state => state.categories.items);
  const selectedCategory = useSelector(state => state.categories.selectedCategory);
  const loading = useSelector(state => state.categories.loading);
  const dispatch = useDispatch();

  if (loading.list) {
    return <CircularProgress/>;
  }

  const handleDelete = category => {
    dispatch(openModal({
      title: `Supprimer ${category.name} ?`,
      content: `Êtes-vous sûr de vouloir supprimer ${category.name} ?`,
      buttons: [
        <Button key='delete' onClick={() => dispatch(removeCategory(category.id))} color="secondary">Supprimer</Button>,
        <Button key='cancel' onClick={() => dispatch(closeModal())} color="primary">Annuler</Button>
      ]
    }));
  };

  const handleEditModal = category => {
    dispatch(fetchCategory(category.id));
    dispatch(openDialog('edit'));
  }

  const treeItems = categories
    .slice()
    .sort((c1, c2) => c1.position > c2.position)
    .map(c => (
    <CategoryTreeItem
      key={c['@id']}
      category={c}
      onMove={(category, position) => dispatch(moveCategory({category, position}))}
      onEdit={category => handleEditModal(category)}
      onDelete={category => handleDelete(category)}
    />
  ));

  return (
    <>
      <Button onClick={() => dispatch(openDialog('add'))} startIcon={<AddIcon/>} variant="contained" color="primary">Ajouter une catégorie</Button>
      <hr/>
      <TreeView
        selected={selectedCategory}
        onNodeSelect={(event, value) => dispatch(setSelectedCategory(value))}
        defaultCollapseIcon={<ArrowDropDownIcon/>}
        defaultExpandIcon={<ArrowRightIcon/>}
      >
        {treeItems}
      </TreeView>
      <CategoryDialog/>
    </>
  );
}
