import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import {closeDialog, saveHighlight} from './highlightSlice';
import HighlightForm from "./HighlightForm";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function HighlightDialog() {
  const mode = useSelector(state => state.highlights.dialog.mode);
  const open = useSelector(state => state.highlights.dialog.open);
  const loading = useSelector(state => state.highlights.loading.form);
  const dispatch = useDispatch();
  const title = mode === 'add' ? 'Ajouter un point fort' : 'Modifier un point fort';

  return (
    <Dialog
      open={open}
      onClose={() => dispatch(closeDialog())}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <HighlightForm/>
      </DialogContent>
      <DialogActions>
        <Button key='cancel' onClick={() => dispatch(closeDialog())} variant="contained" color="default">Annuler</Button>
        <Button key={mode} onClick={() => dispatch(saveHighlight())} disabled={loading} variant="contained" color="primary" startIcon={loading ? <CircularProgress size={14}/> : <CheckIcon/>}>
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
