import React from 'react';
import {useDispatch} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {updateFeature, addFeatureImage, removeFeatureImage} from "../versionSlice";
import FileUpload from "../../../../../components/file-upload/FileUpload";

export default function VersionFeatureForm(props) {
  const {versionFeature} = props;
  const dispatch = useDispatch();

  return (
    <Grid container direction="column">
      <Grid item>
        <FormControlLabel
          control={<Switch checked={versionFeature.standard} onChange={e => dispatch(updateFeature({id: versionFeature.id, prop: 'standard', value: e.target.checked}))}/>}
          label="Standard"
        />
        <FormControlLabel
          control={<Switch checked={versionFeature.preSelected} onChange={e => dispatch(updateFeature({id: versionFeature.id, prop: 'preSelected', value: e.target.checked}))}/>}
          label="Pré-sélectionné"
        />
        <FormControlLabel
          control={<Switch checked={versionFeature.unavailable} onChange={e => dispatch(updateFeature({id: versionFeature.id, prop: 'unavailable', value: e.target.checked}))}/>}
          label="Indisponible"
        />
      </Grid>
      <Grid item>
        <fieldset>
          <legend>Images</legend>
          <FileUpload
            files={versionFeature.images}
            multiple={true}
            addFile={file => dispatch(addFeatureImage({ feature: versionFeature, image: file }))}
            removeFile={file => dispatch(removeFeatureImage({ feature: versionFeature, image: file }))}
          />
        </fieldset>
      </Grid>
    </Grid>
  );
}
