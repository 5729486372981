import axios from 'axios';
import { getToken } from './jwt.service';

axios.defaults.baseURL = process.env.REACT_APP_API_ENTRYPOINT;

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
  },

  async query(resource, slug = '', config = {}) {
    let url = resource;
    if (slug !== '') {
      url += '/' + slug;
    }
    const response = await axios.get(url, config);
    return response.data['@type'] === 'hydra:Collection' ? response.data['hydra:member'] : response.data;
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @param config
   */
  async get(resource, slug = '', config = {}) {
    const response = await axios.get(`${resource}/${slug}`, config);
    return response.data['@type'] === 'hydra:Collection' ? response.data['hydra:member'] : response.data;
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param config
   * @param callbacks
   */
  async post(resource, params, config = {}, callbacks = []) {
    const response = await axios.post(`${resource}`, params);
    if (typeof callbacks[response.status] === 'function') {
      callbacks[response.status](response);
    }
    return response.data;
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param slug
   * @param params
   * @param config
   * @param callbacks
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  async put(resource, slug, params, config = {}, callbacks = []) {
    const response = await axios.put(`${resource}/${slug}`, params, config);
    if (typeof callbacks[response.status] === 'function') {
      callbacks[response.status](response);
    }
    return response['data'];
  },

  /**
   * Send the PATCH HTTP request
   * @param resource
   * @param slug
   * @param params
   * @param config
   * @param callbacks
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  async patch(resource, slug, params, config = {}, callbacks = []) {
    const response = await axios.patch(`${resource}/${slug}`, params, {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'application/merge-patch+json'
      }
    });
    if (typeof callbacks[response.status] === 'function') {
      callbacks[response.status](response);
    }
    return response['data'];
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param slug
   * @param config
   * @param callbacks
   */
  async delete(resource, slug, config = {}, callbacks = []) {
    const response = await axios.delete(`${resource}/${slug}`, config);
    if (typeof callbacks[response.status] === 'function') {
      callbacks[response.status](response);
    }
    return response;
  }
};

export default ApiService;
