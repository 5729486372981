import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Preview.scss';

export default function Preview(props) {
  const { file, remove, loading } = props;

  if (loading) {
    return <CircularProgress/>;
  }

  return (
    <div className='preview'>
      <img key={file['@id']} src={process.env.REACT_APP_API_ENTRYPOINT + file.contentUrl} alt={file['@id']}/>
      <IconButton onClick={() => remove(file)} disabled={loading} color="secondary" aria-label="Supprimer">
        <DeleteIcon/>
      </IconButton>
    </div>
  );
}
