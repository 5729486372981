import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch } from 'react-redux';
import UserRow from './UserRow';
import UserDialog from '../UserDialog';
import {fetchUsers, openDialog} from '../userSlice';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function UserList() {
  const classes = useStyles();
  const users = useSelector(state => state.users.items);
  const loading = useSelector(state => state.users.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  if (loading.list) {
    return <CircularProgress/>;
  }

  const rows = users.map(u => <UserRow key={u.id} user={u}/>);

  return (
    <>
      <Button onClick={() => dispatch(openDialog('add'))} startIcon={<AddIcon/>} variant="contained" color="primary">Ajouter un utilisateur</Button>
      <hr/>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
      <UserDialog/>
    </>
  );
}
